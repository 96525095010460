// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-mdx": () => import("./../../src/pages/404.mdx" /* webpackChunkName: "component---src-pages-404-mdx" */),
  "component---src-pages-capital-changelog-styles-tsx": () => import("./../../src/pages/capital/changelog-styles.tsx" /* webpackChunkName: "component---src-pages-capital-changelog-styles-tsx" */),
  "component---src-pages-capital-changelog-tsx": () => import("./../../src/pages/capital/changelog.tsx" /* webpackChunkName: "component---src-pages-capital-changelog-tsx" */),
  "component---src-pages-capital-components-accordionwizard-md": () => import("./../../src/pages/capital/components/accordionwizard.md" /* webpackChunkName: "component---src-pages-capital-components-accordionwizard-md" */),
  "component---src-pages-capital-components-axis-md": () => import("./../../src/pages/capital/components/axis.md" /* webpackChunkName: "component---src-pages-capital-components-axis-md" */),
  "component---src-pages-capital-components-badge-md": () => import("./../../src/pages/capital/components/badge.md" /* webpackChunkName: "component---src-pages-capital-components-badge-md" */),
  "component---src-pages-capital-components-blocklist-md": () => import("./../../src/pages/capital/components/blocklist.md" /* webpackChunkName: "component---src-pages-capital-components-blocklist-md" */),
  "component---src-pages-capital-components-brandfooter-md": () => import("./../../src/pages/capital/components/brandfooter.md" /* webpackChunkName: "component---src-pages-capital-components-brandfooter-md" */),
  "component---src-pages-capital-components-breadcrumb-md": () => import("./../../src/pages/capital/components/breadcrumb.md" /* webpackChunkName: "component---src-pages-capital-components-breadcrumb-md" */),
  "component---src-pages-capital-components-button-md": () => import("./../../src/pages/capital/components/button.md" /* webpackChunkName: "component---src-pages-capital-components-button-md" */),
  "component---src-pages-capital-components-buttongroup-md": () => import("./../../src/pages/capital/components/buttongroup.md" /* webpackChunkName: "component---src-pages-capital-components-buttongroup-md" */),
  "component---src-pages-capital-components-card-md": () => import("./../../src/pages/capital/components/card.md" /* webpackChunkName: "component---src-pages-capital-components-card-md" */),
  "component---src-pages-capital-components-charactercounter-md": () => import("./../../src/pages/capital/components/charactercounter.md" /* webpackChunkName: "component---src-pages-capital-components-charactercounter-md" */),
  "component---src-pages-capital-components-charttooltip-md": () => import("./../../src/pages/capital/components/charttooltip.md" /* webpackChunkName: "component---src-pages-capital-components-charttooltip-md" */),
  "component---src-pages-capital-components-checkbox-md": () => import("./../../src/pages/capital/components/checkbox.md" /* webpackChunkName: "component---src-pages-capital-components-checkbox-md" */),
  "component---src-pages-capital-components-colorswatch-md": () => import("./../../src/pages/capital/components/colorswatch.md" /* webpackChunkName: "component---src-pages-capital-components-colorswatch-md" */),
  "component---src-pages-capital-components-contentblock-md": () => import("./../../src/pages/capital/components/contentblock.md" /* webpackChunkName: "component---src-pages-capital-components-contentblock-md" */),
  "component---src-pages-capital-components-description-md": () => import("./../../src/pages/capital/components/description.md" /* webpackChunkName: "component---src-pages-capital-components-description-md" */),
  "component---src-pages-capital-components-dialog-md": () => import("./../../src/pages/capital/components/dialog.md" /* webpackChunkName: "component---src-pages-capital-components-dialog-md" */),
  "component---src-pages-capital-components-divider-md": () => import("./../../src/pages/capital/components/divider.md" /* webpackChunkName: "component---src-pages-capital-components-divider-md" */),
  "component---src-pages-capital-components-dropdown-md": () => import("./../../src/pages/capital/components/dropdown.md" /* webpackChunkName: "component---src-pages-capital-components-dropdown-md" */),
  "component---src-pages-capital-components-error-md": () => import("./../../src/pages/capital/components/error.md" /* webpackChunkName: "component---src-pages-capital-components-error-md" */),
  "component---src-pages-capital-components-filterableinput-md": () => import("./../../src/pages/capital/components/filterableinput.md" /* webpackChunkName: "component---src-pages-capital-components-filterableinput-md" */),
  "component---src-pages-capital-components-footprintbutton-md": () => import("./../../src/pages/capital/components/footprintbutton.md" /* webpackChunkName: "component---src-pages-capital-components-footprintbutton-md" */),
  "component---src-pages-capital-components-formitem-md": () => import("./../../src/pages/capital/components/formitem.md" /* webpackChunkName: "component---src-pages-capital-components-formitem-md" */),
  "component---src-pages-capital-components-header-md": () => import("./../../src/pages/capital/components/header.md" /* webpackChunkName: "component---src-pages-capital-components-header-md" */),
  "component---src-pages-capital-components-icon-md": () => import("./../../src/pages/capital/components/icon.md" /* webpackChunkName: "component---src-pages-capital-components-icon-md" */),
  "component---src-pages-capital-components-illustration-md": () => import("./../../src/pages/capital/components/illustration.md" /* webpackChunkName: "component---src-pages-capital-components-illustration-md" */),
  "component---src-pages-capital-components-imagecard-md": () => import("./../../src/pages/capital/components/imagecard.md" /* webpackChunkName: "component---src-pages-capital-components-imagecard-md" */),
  "component---src-pages-capital-components-index-tsx": () => import("./../../src/pages/capital/components/index.tsx" /* webpackChunkName: "component---src-pages-capital-components-index-tsx" */),
  "component---src-pages-capital-components-input-md": () => import("./../../src/pages/capital/components/input.md" /* webpackChunkName: "component---src-pages-capital-components-input-md" */),
  "component---src-pages-capital-components-inputgroup-md": () => import("./../../src/pages/capital/components/inputgroup.md" /* webpackChunkName: "component---src-pages-capital-components-inputgroup-md" */),
  "component---src-pages-capital-components-key-md": () => import("./../../src/pages/capital/components/key.md" /* webpackChunkName: "component---src-pages-capital-components-key-md" */),
  "component---src-pages-capital-components-label-md": () => import("./../../src/pages/capital/components/label.md" /* webpackChunkName: "component---src-pages-capital-components-label-md" */),
  "component---src-pages-capital-components-legend-md": () => import("./../../src/pages/capital/components/legend.md" /* webpackChunkName: "component---src-pages-capital-components-legend-md" */),
  "component---src-pages-capital-components-linechart-md": () => import("./../../src/pages/capital/components/linechart.md" /* webpackChunkName: "component---src-pages-capital-components-linechart-md" */),
  "component---src-pages-capital-components-link-md": () => import("./../../src/pages/capital/components/link.md" /* webpackChunkName: "component---src-pages-capital-components-link-md" */),
  "component---src-pages-capital-components-listtable-md": () => import("./../../src/pages/capital/components/listtable.md" /* webpackChunkName: "component---src-pages-capital-components-listtable-md" */),
  "component---src-pages-capital-components-loadingblock-md": () => import("./../../src/pages/capital/components/loadingblock.md" /* webpackChunkName: "component---src-pages-capital-components-loadingblock-md" */),
  "component---src-pages-capital-components-loadingspinner-md": () => import("./../../src/pages/capital/components/loadingspinner.md" /* webpackChunkName: "component---src-pages-capital-components-loadingspinner-md" */),
  "component---src-pages-capital-components-modal-md": () => import("./../../src/pages/capital/components/modal.md" /* webpackChunkName: "component---src-pages-capital-components-modal-md" */),
  "component---src-pages-capital-components-piechart-md": () => import("./../../src/pages/capital/components/piechart.md" /* webpackChunkName: "component---src-pages-capital-components-piechart-md" */),
  "component---src-pages-capital-components-pill-md": () => import("./../../src/pages/capital/components/pill.md" /* webpackChunkName: "component---src-pages-capital-components-pill-md" */),
  "component---src-pages-capital-components-radiobutton-md": () => import("./../../src/pages/capital/components/radiobutton.md" /* webpackChunkName: "component---src-pages-capital-components-radiobutton-md" */),
  "component---src-pages-capital-components-reorderableblocklist-md": () => import("./../../src/pages/capital/components/reorderableblocklist.md" /* webpackChunkName: "component---src-pages-capital-components-reorderableblocklist-md" */),
  "component---src-pages-capital-components-select-md": () => import("./../../src/pages/capital/components/select.md" /* webpackChunkName: "component---src-pages-capital-components-select-md" */),
  "component---src-pages-capital-components-sidebar-md": () => import("./../../src/pages/capital/components/sidebar.md" /* webpackChunkName: "component---src-pages-capital-components-sidebar-md" */),
  "component---src-pages-capital-components-stackedbarchart-md": () => import("./../../src/pages/capital/components/stackedbarchart.md" /* webpackChunkName: "component---src-pages-capital-components-stackedbarchart-md" */),
  "component---src-pages-capital-components-switch-md": () => import("./../../src/pages/capital/components/switch.md" /* webpackChunkName: "component---src-pages-capital-components-switch-md" */),
  "component---src-pages-capital-components-table-md": () => import("./../../src/pages/capital/components/table.md" /* webpackChunkName: "component---src-pages-capital-components-table-md" */),
  "component---src-pages-capital-components-tabs-md": () => import("./../../src/pages/capital/components/tabs.md" /* webpackChunkName: "component---src-pages-capital-components-tabs-md" */),
  "component---src-pages-capital-components-targetstatus-md": () => import("./../../src/pages/capital/components/targetstatus.md" /* webpackChunkName: "component---src-pages-capital-components-targetstatus-md" */),
  "component---src-pages-capital-components-text-md": () => import("./../../src/pages/capital/components/text.md" /* webpackChunkName: "component---src-pages-capital-components-text-md" */),
  "component---src-pages-capital-components-textarea-md": () => import("./../../src/pages/capital/components/textarea.md" /* webpackChunkName: "component---src-pages-capital-components-textarea-md" */),
  "component---src-pages-capital-components-toast-md": () => import("./../../src/pages/capital/components/toast.md" /* webpackChunkName: "component---src-pages-capital-components-toast-md" */),
  "component---src-pages-capital-components-tooltip-md": () => import("./../../src/pages/capital/components/tooltip.md" /* webpackChunkName: "component---src-pages-capital-components-tooltip-md" */),
  "component---src-pages-capital-components-wizard-md": () => import("./../../src/pages/capital/components/wizard.md" /* webpackChunkName: "component---src-pages-capital-components-wizard-md" */),
  "component---src-pages-capital-index-tsx": () => import("./../../src/pages/capital/index.tsx" /* webpackChunkName: "component---src-pages-capital-index-tsx" */),
  "component---src-pages-capital-overview-contributing-designers-md": () => import("./../../src/pages/capital/overview/contributing/designers.md" /* webpackChunkName: "component---src-pages-capital-overview-contributing-designers-md" */),
  "component---src-pages-capital-overview-contributing-developers-md": () => import("./../../src/pages/capital/overview/contributing/developers.md" /* webpackChunkName: "component---src-pages-capital-overview-contributing-developers-md" */),
  "component---src-pages-capital-overview-gettingstarted-designers-md": () => import("./../../src/pages/capital/overview/gettingstarted/designers.md" /* webpackChunkName: "component---src-pages-capital-overview-gettingstarted-designers-md" */),
  "component---src-pages-capital-overview-gettingstarted-developers-md": () => import("./../../src/pages/capital/overview/gettingstarted/developers.md" /* webpackChunkName: "component---src-pages-capital-overview-gettingstarted-developers-md" */),
  "component---src-pages-capital-overview-glossary-tsx": () => import("./../../src/pages/capital/overview/glossary.tsx" /* webpackChunkName: "component---src-pages-capital-overview-glossary-tsx" */),
  "component---src-pages-capital-overview-index-tsx": () => import("./../../src/pages/capital/overview/index.tsx" /* webpackChunkName: "component---src-pages-capital-overview-index-tsx" */),
  "component---src-pages-capital-overview-introduction-md": () => import("./../../src/pages/capital/overview/introduction.md" /* webpackChunkName: "component---src-pages-capital-overview-introduction-md" */),
  "component---src-pages-capital-patterns-index-tsx": () => import("./../../src/pages/capital/patterns/index.tsx" /* webpackChunkName: "component---src-pages-capital-patterns-index-tsx" */),
  "component---src-pages-capital-patterns-vocabulary-md": () => import("./../../src/pages/capital/patterns/vocabulary.md" /* webpackChunkName: "component---src-pages-capital-patterns-vocabulary-md" */),
  "component---src-pages-capital-styles-color-md": () => import("./../../src/pages/capital/styles/color.md" /* webpackChunkName: "component---src-pages-capital-styles-color-md" */),
  "component---src-pages-capital-styles-color-viz-md": () => import("./../../src/pages/capital/styles/color-viz.md" /* webpackChunkName: "component---src-pages-capital-styles-color-viz-md" */),
  "component---src-pages-capital-styles-iconography-md": () => import("./../../src/pages/capital/styles/iconography.md" /* webpackChunkName: "component---src-pages-capital-styles-iconography-md" */),
  "component---src-pages-capital-styles-icons-tsx": () => import("./../../src/pages/capital/styles/icons.tsx" /* webpackChunkName: "component---src-pages-capital-styles-icons-tsx" */),
  "component---src-pages-capital-styles-illustration-md": () => import("./../../src/pages/capital/styles/illustration.md" /* webpackChunkName: "component---src-pages-capital-styles-illustration-md" */),
  "component---src-pages-capital-styles-index-tsx": () => import("./../../src/pages/capital/styles/index.tsx" /* webpackChunkName: "component---src-pages-capital-styles-index-tsx" */),
  "component---src-pages-capital-styles-stylesheet-md": () => import("./../../src/pages/capital/styles/stylesheet.md" /* webpackChunkName: "component---src-pages-capital-styles-stylesheet-md" */),
  "component---src-pages-capital-styles-tokens-tsx": () => import("./../../src/pages/capital/styles/tokens.tsx" /* webpackChunkName: "component---src-pages-capital-styles-tokens-tsx" */),
  "component---src-pages-capital-styles-typography-md": () => import("./../../src/pages/capital/styles/typography.md" /* webpackChunkName: "component---src-pages-capital-styles-typography-md" */),
  "component---src-pages-index-tsx": () => import("./../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */)
}

